import { UserSession } from '@/utils/withServerSideProps'
import { Session } from 'next-auth'
import { useSession } from 'next-auth/react'

export type SessionStates = 'loading' | 'authenticated' | 'unauthenticated'

export const useUserSession: () => [
    UserSession | null,
    SessionStates,
    (data?: any) => Promise<Session | null>
] = () => {
    const { data: session, status, update } = useSession()

    return [session as UserSession, status, update]
}
