import React, { useEffect } from 'react'
import { User } from './types'
import { signOut, useSession } from 'next-auth/react'
import { UserSession } from './utils/withServerSideProps'
import { useUserSession } from './hooks/useUserSession'
import { add, differenceInSeconds, fromUnixTime, isAfter } from 'date-fns'
import { useRouter } from 'next/router'

const UserContext = React.createContext<User | null | undefined>(undefined)

export const useUser = () => {
    const context = React.useContext(UserContext)

    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider')
    }

    return context
}

export const UserProvider: React.FC<{ user?: User | null }> = ({ user, children }) => {
    const [session, , update] = useUserSession()
    const router = useRouter()

    useEffect(() => {
        if (session == null) {
            return
        }

        const intervalTimeout = 5 * 1000

        const interval = setInterval(async () => {
            const expireInSeconds = differenceInSeconds(
                add(fromUnixTime(session.accessTokenExpires), { seconds: 5 }),
                new Date()
            )

            // console.log(
            //     `access token ending in ${session.accessToken.substring(
            //         session.accessToken.length - 10
            //     )} expires in ${expireInSeconds} seconds`
            // )

            if (expireInSeconds <= 5) {
                //  console.log('token will expire, signing out')
                signOut()
            }

            // await update()
            // refresh session each 5 minutes, which would refresh the access token if needed
        }, intervalTimeout)

        return () => {
            clearInterval(interval)
        }
    }, [router, session, update])

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}
