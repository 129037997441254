import React, { FunctionComponent, useState } from 'react'
import { signOut } from 'next-auth/react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import Logout from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import { useTranslation } from 'next-i18next'
import { CircularProgress } from '@mui/material'
import { useUserSession } from '@/hooks/useUserSession'
import { UserAvatar } from './UserAvatar'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { FyButton } from './styled/Buttons'

export const CurrentUser: FunctionComponent = () => {
    const [session, sessionState] = useUserSession()
    const { t } = useTranslation('common')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const router = useRouter()
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            {sessionState === 'loading' ? (
                <CircularProgress size={16} />
            ) : session ? (
                <>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        {session.user && <UserAvatar user={session.user} />}
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                        }}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom',
                        }}
                    >
                        <NextLink href="/settings" passHref legacyBehavior>
                            <MenuItem>
                                <ListItemIcon>
                                    <SettingsIcon fontSize="small" />
                                </ListItemIcon>
                                {t('user.settings')}
                            </MenuItem>
                        </NextLink>

                        <MenuItem
                            onClick={() => {
                                signOut()
                            }}
                        >
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            {t('user.disconnect')}
                        </MenuItem>
                    </Menu>
                </>
            ) : (
                <NextLink passHref href={`/auth/signin?callbackUrl=${encodeURIComponent(router.route)}`}>
                    <FyButton>{t('connection.connectionButtonLabel')}</FyButton>
                </NextLink>
            )}
        </>
    )
}
