/* eslint-disable max-classes-per-file */

import React, { FunctionComponent, ReactNode, useState } from 'react'
import { CurrentUser } from './components/CurrentUser'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import NextLink from 'next/link'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { setCookie } from './utils/cookies'
import { Drawer, List, ListItem, ListItemButton, ListItemText, useTheme } from '@mui/material'
import { TeamsResponse } from './types'
import EmailIcon from '@mui/icons-material/Email'

export const Footer: FunctionComponent = () => {
    const { t } = useTranslation('common')
    const theme = useTheme()

    return (
        <footer>
            <Container
                sx={{
                    my: 5,
                    // display: { xs: 'none', md: 'inherit' },
                }}
            >
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={8}>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            {t('pageLayout.footer.about')}
                        </Typography>
                        <Box display="flex" flexDirection="column" gap="3px">
                            <Link color="#ccc" href="/tos" component={NextLink}>
                                {t('pageLayout.footer.tos')}
                            </Link>

                            <Link color="#ccc" href="/privacy" component={NextLink}>
                                {t('pageLayout.footer.privacy')}
                            </Link>

                            <Link target="_blank" rel="noopener" href="mailto:info@footory.io" color="#ccc">
                                {t('pageLayout.footer.contact')}
                            </Link>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            {t('pageLayout.footer.help')}
                        </Typography>
                        <Box display="flex" flexDirection="column" gap="3px">
                            <Box>
                                <Link
                                    href="https://m.me/217299040303856"
                                    target="_blank"
                                    rel="noopener"
                                    color="#ccc"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="35px"
                                        height="35px"
                                        viewBox="0 0 24 24"
                                        style={{ verticalAlign: 'middle' }}
                                    >
                                        <path
                                            fill="#ccc"
                                            d="M12 2C6.36 2 2 6.13 2 11.7c0 2.91 1.19 5.44 3.14 7.17c.16.13.26.35.27.57l.05 1.78c.04.57.61.94 1.13.71l1.98-.87c.17-.06.36-.09.53-.06c.9.27 1.9.4 2.9.4c5.64 0 10-4.13 10-9.7C22 6.13 17.64 2 12 2m6 7.46l-2.93 4.67c-.47.73-1.47.92-2.17.37l-2.34-1.73a.6.6 0 0 0-.72 0l-3.16 2.4c-.42.33-.97-.17-.68-.63l2.93-4.67c.47-.73 1.47-.92 2.17-.4l2.34 1.76a.6.6 0 0 0 .72 0l3.16-2.4c.42-.33.97.17.68.63"
                                        ></path>
                                    </svg>
                                </Link>
                                &nbsp;
                                <Link
                                    href="https://m.me/217299040303856"
                                    target="_blank"
                                    rel="noopener"
                                    color="#ccc"
                                >
                                    {t('messengerSupport')}
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    href="https://ig.me/m/footoryio"
                                    target="_blank"
                                    rel="noopener"
                                    color="#ccc"
                                >
                                    <InstagramIcon
                                        fontSize="large"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                </Link>
                                &nbsp;
                                <Link
                                    href="https://ig.me/m/footoryio"
                                    target="_blank"
                                    rel="noopener"
                                    color="#ccc"
                                >
                                    {t('instagramSupport')}
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    href="mailto:info@footory.io"
                                    target="_blank"
                                    rel="noopener"
                                    color="#ccc"
                                >
                                    <EmailIcon fontSize="large" style={{ verticalAlign: 'middle' }} />
                                </Link>
                                &nbsp;
                                <Link
                                    href="mailto:info@footory.io"
                                    target="_blank"
                                    rel="noopener"
                                    color="#ccc"
                                >
                                    {t('emailSupport')}
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            {t('pageLayout.footer.follow')}
                        </Typography>
                        <Box display="flex" flexDirection="row" gap="10px">
                            <Link
                                target="_blank"
                                rel="noopener"
                                href="https://www.facebook.com/footoryio"
                                color="#ccc"
                            >
                                <FacebookIcon fontSize="large" />
                            </Link>
                            <Link
                                target="_blank"
                                rel="noopener"
                                href="https://www.instagram.com/footoryio"
                                color="#ccc"
                            >
                                <InstagramIcon fontSize="large" />
                            </Link>
                        </Box>
                    </Box>
                </Stack>
            </Container>
        </footer>
    )
}

interface IResponsiveContainerProps {
    children?: ReactNode
    myTeams?: TeamsResponse
    home?: boolean
}

const ResponsiveContainer = ({ myTeams, home, children }: IResponsiveContainerProps) => {
    const router = useRouter()
    const [drawerState, setDrawerState] = useState(false)

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }

        setDrawerState(open)
    }

    const pages: { key: string; title: string; url: string }[] = home
        ? []
        : [
              //   {
              //       key: 'fields',
              //       title: t('pageLayout.pages.fields'),
              //       url: '/fields',
              //   },
              //   // {
              //   //     key: 'schedules',
              //   //     title: t('pageLayout.pages.schedules'),
              //   //     url: '/schedules',
              //   // },
              //   {
              //       key: 'teams',
              //       title: t('pageLayout.pages.teams'),
              //       url: '/teams',
              //   },
              //   {
              //       key: 'blog',
              //       title: t('pageLayout.pages.blog'),
              //       url: '/blog',
              //   },
          ]

    // if (!home && myTeams && myTeams.teams.length > 0) {
    //     pages.push({
    //         key: 'agenda',
    //         title: t('pageLayout.pages.agenda'),
    //         url: '/agenda',
    //     })
    // }

    return (
        <>
            <Container
                sx={{
                    mt: 1,
                }}
            >
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'inherit' } }}
                    >
                        <a href="/">
                            <img
                                alt="Footory"
                                src="/header.png"
                                width="100%"
                                className="crisp"
                                style={{ maxWidth: '200px' }}
                            />
                        </a>
                    </Typography>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'inherit', md: 'none' },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={toggleDrawer(true)}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="left" open={drawerState} onClose={toggleDrawer(false)}>
                            <Box
                                sx={{
                                    width: 250,
                                }}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                            >
                                <List>
                                    {pages.map((page) => (
                                        <ListItem key={page.key} disablePadding>
                                            <ListItemButton
                                                selected={router.asPath.startsWith(page.url)}
                                                component="a"
                                                href={page.url}
                                            >
                                                <ListItemText primary={page.title} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                    {/* locales */}
                                    <ListItem
                                        key={router.locale === 'en' ? 'fr' : 'en'}
                                        sx={{ my: 2 }}
                                        disablePadding
                                    >
                                        <ListItemButton
                                            onClick={() => {
                                                const locale = router.locale === 'en' ? 'fr' : 'en'
                                                setCookie('NEXT_LOCALE', locale, 30)
                                                router.push(router.asPath, undefined, {
                                                    locale,
                                                })
                                            }}
                                        >
                                            {router.locale === 'en' ? 'fr' : 'en'}
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Box>
                        </Drawer>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'inherit', md: 'none' },
                        }}
                    >
                        <a href="/">
                            <img
                                alt="Footory"
                                src="/header.png"
                                width="100%"
                                className="crisp"
                                style={{ maxWidth: '115px' }}
                            />
                        </a>
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'inherit' },
                        }}
                    >
                        {pages.map((page) => (
                            <NextLink key={page.key} href={page.url} passHref>
                                <Button sx={{ my: 2, display: 'block' }}>{page.title}</Button>
                            </NextLink>
                        ))}
                        {/* locales */}
                        <Button
                            key={router.locale === 'en' ? 'fr' : 'en'}
                            sx={{ my: 2, display: 'block' }}
                            onClick={() => {
                                const locale = router.locale === 'en' ? 'fr' : 'en'
                                setCookie('NEXT_LOCALE', locale, 30)
                                router.push(router.asPath, undefined, {
                                    locale,
                                })
                            }}
                        >
                            {router.locale === 'en' ? 'fr' : 'en'}
                        </Button>
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <CurrentUser />
                    </Box>
                </Toolbar>
            </Container>
            {children}
            <Footer />
        </>
    )
}

interface IPageLayoutProps {
    children: ReactNode
    myTeams?: TeamsResponse
    home?: boolean
}

const PageLayout = (props: IPageLayoutProps) => {
    return <ResponsiveContainer {...props}>{props.children}</ResponsiveContainer>
}

export default PageLayout
