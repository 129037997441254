import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ForwardedRef, forwardRef, useState } from 'react'
import ShortcutIcon from '@mui/icons-material/Shortcut'

export const FyButton = styled(Button)((ctx) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    fontWeight: 700,
    '&:disabled': {
        boxShadow: 'none',
    },
    '&:hover': {
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    ...ctx.style,
}))

export const FyActionButton = forwardRef<HTMLButtonElement, ButtonProps>(function ActionButton(
    props: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
) {
    const { children, disabled, endIcon, ...other } = props
    const [loading, setLoading] = useState(false)

    return (
        <FyButton
            ref={ref}
            {...other}
            onClick={async (e) => {
                setLoading(true)
                try {
                    other.onClick && (await other.onClick(e))
                } finally {
                    setLoading(false)
                }
            }}
            disabled={loading || disabled}
            endIcon={
                loading ? (
                    <CircularProgress size="16px" sx={{ color: '#fff' }} />
                ) : (
                    endIcon ?? <ShortcutIcon />
                )
            }
        >
            {children}
        </FyButton>
    )
})
